import { Component } from "react";
import Helmet from "react-helmet";
import config from "../../utils/siteConfig";

// Services
import intl from "../../services/intl";

class GeneralTags extends Component {
  static defaultProps = {
    defaultTitle: config.siteTitle,
    title: config.siteTitle,
    description: config.siteDescription,
    image: {
      url: config.siteUrl + config.shareImage,
      width: config.shareImageWidth,
      height: config.shareImageHeight,
    },
    pagePath: "",
    pageUrl: config.siteUrl,
    noIndex: false,
  };

  generateLinks() {
    const { pagePath } = this.props;
    const { defaultLocale, activeLocales } = intl;
    const domain = process.env.GATSBY_URL;

    const printHref = (domain, path, locale) =>
      [domain, locale, path]
        .filter((h) => h)
        .join("/")
        .replace(/([^:]\/)\/+/g, "$1");

    return [
      {
        rel: "alternate",
        hrefLang: "x-default",
        href: printHref(domain, pagePath, defaultLocale.path),
      },
      ...activeLocales.map((locale) => ({
        rel: "alternate",
        hrefLang: locale.locale,
        href: printHref(domain, pagePath, locale.path),
      })),
    ];
  }

  render() {
    const {
      defaultTitle = intl.t("site-config.default-title", "Ritual"),
      title = intl.t("site-config.title", "Ritual"),
      description = intl.t("site-config.description", "Description"),
      image,
      image: {
        url: imgUrlPartial = intl.t("site-config.url", "https://ritual.com") +
          intl.t("site-config.share-image", "/logos/share.jpg"),
        width: imgWidth = intl.t("site-config.share-image-width", "1200"),
        height: imgHeight = intl.t("site-config.share-image-height", "630"),
      },
      pagePath = "",
      noIndex = false,
      ogTitle = intl.t("site-config.default-title", "Ritual"),
    } = this.props;

    const pageUrl = `${this.props.pageUrl}/${intl
      .localizePath(pagePath)
      .replace(/^\/+/g, "")}`;

    if (!title || !description || !image) {
      console.warn(
        `Information missing from page SEO : ${title ? "" : "--title "} ${
          description ? "" : "--description "
        } ${image ? "" : "--image "}`,
      );
    }

    let htmlAttributes = {
      lang: intl.siteLang,
    };

    const imgUrl = `${imgUrlPartial}`;

    // Default Website Schema
    const schemaOrgJSONLD = {
      "@context": "http://schema.org",
      "@graph": [
        {
          "@type": "WebSite",
          url: intl.t("site-config.url", "https://ritual.com"),
          name: intl.t("site-config.default-title", "Ritual"),
          alternateName: intl.t("site-config.title-alt", "Ritual"),
          publishingPrinciples: config.editorialPolicy,
          provider: {
            "@type": "Organization",
            name: config.publisher,
            url: config.siteUrl,
            logo: {
              "@type": "ImageObject",
              width: 512,
              height: 512,
              url: config.siteLogo,
            },
            hasMerchantReturnPolicy: {
              "@type": "MerchantReturnPolicy",
              merchantReturnLink: config.returnPolicy,
              applicableCountry: "US",
              description: "30 Money-Back Guarantee without Product Return",
              merchantReturnDays: 30,
              returnFees: 0,
            },
          },
        },
        {
          "@type": "WebPage",
          url: pageUrl,
          name: title,
          alternateName: intl.t("site-config.title-alt", "Ritual"),
        },
      ],
    };

    let genericMeta = [
      { name: "image", content: imgUrl },
      { name: "description", content: description },
    ];

    if (noIndex === true) {
      genericMeta.push({ name: "robots", content: "noindex" });
    }

    let openGraphMeta = [
      { property: "og:locale", content: intl.ogLocale },
      { property: "og:title", content: ogTitle || title },
      { property: "og:description", content: description },
      { property: "og:image", content: imgUrl },
      { property: "og:image:width", content: imgWidth },
      { property: "og:image:height", content: imgHeight },
      { property: "og:type", content: "website" },
      { property: "og:site_name", content: defaultTitle },
      { property: "og:url", content: pageUrl },
    ];

    let facebookMeta = [
      { property: "fb:pages", content: config.fbPageId || "" },
    ];

    let twitterMeta = [
      { name: "twitter:title", content: title },
      { name: "twitter:description", content: description },
      { name: "twitter:image", content: imgUrl },
      { name: "twitter:card", content: "summary_large_image" },
      { name: "twitter:creator", content: config.userTwitter || "" },
    ];

    let meta = [
      ...genericMeta,
      ...openGraphMeta,
      ...facebookMeta,
      ...twitterMeta,
    ];

    const links = this.generateLinks();

    return (
      <Helmet
        htmlAttributes={htmlAttributes}
        title={title}
        defaultTitle={defaultTitle}
        meta={meta}
        link={links}
        defer={false}
      >
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>
      </Helmet>
    );
  }
}

export default GeneralTags;
